<template>
  <DefaultPage :title="$t('app.user.onboarding.recyclingStreets.title')">
    <template #left>
      <BackButton path="/user/onboarding" />
    </template>
    <div>
      <div class="p-3">
        {{ $t("app.user.onboarding.recyclingStreets.instruction") }}
        <a href="#" class="skip-step" @click.prevent="finish">{{
          $t("app.user.onboarding.recyclingStreets.skipStep")
        }}</a>
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          :placeholder="$t('shared.filter.instruction')"
          class="mt-2"
          debounce="500"
        ></b-form-input>
      </div>
      <DefaultList ref="list" :url="url" :urlParams="urlParams">
        <template #item="{ item }">
          <div class="p-3">
            <div class="list-item-title">{{ item.name }}</div>
            <a
              href="#"
              @click.prevent="itemClicked(item)"
              class="stretched-link"
            ></a>
          </div>
        </template>
      </DefaultList>
    </div>
  </DefaultPage>
</template>

<script>
import httpService from "@/services/HttpService";
import BackButton from "@/components/BackButton.vue";

export default {
  components: {
    BackButton,
  },
  data() {
    return {
      filter: "",
      urlParams: {},
      recyclingStreetId: 0,
    };
  },
  computed: {
    url() {
      return `/api/places/${this.$route.params.placeId}/recycling-streets`;
    },
  },
  watch: {
    /* istanbul ignore next */
    filter(newVal) {
      if (newVal != "") {
        this.urlParams.keyword = newVal;
      } else {
        delete this.urlParams.keyword;
      }
      this.$refs.list.refreshData();
    },
  },
  methods: {
    itemClicked(item) {
      httpService.put(`/api/user/recycling-streets/${item.id}`).then(() => {
        this.recyclingStreetId = item.id;
        this.showMsgBox();
      });
    },
    showMsgBox() {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("app.user.onboarding.recyclingStreets.notifications.message"),
          {
            title: this.$t(
              "app.user.onboarding.recyclingStreets.notifications.title"
            ),
            okTitle: this.$t(
              "app.user.onboarding.recyclingStreets.notifications.okTitle"
            ),
            cancelTitle: this.$t(
              "app.user.onboarding.recyclingStreets.notifications.cancelTitle"
            ),
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            /* istanbul ignore next */
            this.activateNotifications();
          } else {
            this.finish();
          }
        })
        /* istanbul ignore next */
        .catch(
          /* istanbul ignore next */ (error) => /* istanbul ignore next */ {
            this.$root.makeErrorToast(error.message);
            this.finish();
          }
        );
    },
    /* istanbul ignore next */
    activateNotifications() {
      if (this.$store.state.notifications.pushRegistrationId) {
        this.patchNotifications();
        return;
      }

      this.$store.dispatch("notifications/registerPush").then(
        () => {
          this.patchNotifications();
        },
        (error) => {
          this.$root.makeErrorToast(error.message);
          this.finish();
        }
      );
    },
    /* istanbul ignore next */
    patchNotifications() {
      httpService
        .patch(`/api/user/recycling-streets/${this.recyclingStreetId}`, {
          notifications_active: true,
        })
        .then(() => {
          this.finish();
        });
    },
    finish() {
      this.$router.push({ path: `/user/home` });
    },
  },
};
</script>
